import { ThemeDemos, IndicatorDemos, CalendarDemos, ModalDemos, HooksDemos, SegmentedControlDemos } from '@mantine/demos';
import * as React from 'react';
export default {
  ThemeDemos,
  IndicatorDemos,
  CalendarDemos,
  ModalDemos,
  HooksDemos,
  SegmentedControlDemos,
  React
};