import { ButtonDemos, SliderDemos, TabsDemos, AccordionDemos, CalendarDemos, ThemeDemos, InputDemos, RangeCalendarDemos } from '@mantine/demos';
import { Slider as SliderStylesApi, Button as ButtonStylesApi } from '@mantine/styles-api';
import * as React from 'react';
export default {
  ButtonDemos,
  SliderDemos,
  TabsDemos,
  AccordionDemos,
  CalendarDemos,
  ThemeDemos,
  InputDemos,
  RangeCalendarDemos,
  SliderStylesApi,
  ButtonStylesApi,
  React
};