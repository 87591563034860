import { TabsDemos, AccordionDemos, TooltipDemos, PopoverDemos, HoverCardDemos, FocusTrapDemos, MenuDemos, AvatarDemos, CardDemos, CopyButtonDemos, FileButtonDemos, InputDemos, ModalDemos, NProgressDemos, RangeCalendarDemos, SliderDemos, NavLinkDemos, ButtonDemos, CarouselDemos, FileInputDemos, DropzoneDemos, ScrollAreaDemos } from '@mantine/demos';
import * as React from 'react';
export default {
  TabsDemos,
  AccordionDemos,
  TooltipDemos,
  PopoverDemos,
  HoverCardDemos,
  FocusTrapDemos,
  MenuDemos,
  AvatarDemos,
  CardDemos,
  CopyButtonDemos,
  FileButtonDemos,
  InputDemos,
  ModalDemos,
  NProgressDemos,
  RangeCalendarDemos,
  SliderDemos,
  NavLinkDemos,
  ButtonDemos,
  CarouselDemos,
  FileInputDemos,
  DropzoneDemos,
  ScrollAreaDemos,
  React
};