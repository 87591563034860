import { AspectRatioDemos, NumberInputDemos, TooltipDemos, SliderDemos, CheckboxDemos, BackgroundImageDemos, SpotlightDemos, OverlayDemos, FormDemos } from '@mantine/demos';
import * as React from 'react';
export default {
  AspectRatioDemos,
  NumberInputDemos,
  TooltipDemos,
  SliderDemos,
  CheckboxDemos,
  BackgroundImageDemos,
  SpotlightDemos,
  OverlayDemos,
  FormDemos,
  React
};