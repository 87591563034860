import { TimelineDemos, DialogDemos, ListDemos, ChipDemos, PaginationDemos, TimeRangeInputDemos, RichTextEditorDemos } from '@mantine/demos';
import * as React from 'react';
export default {
  TimelineDemos,
  DialogDemos,
  ListDemos,
  ChipDemos,
  PaginationDemos,
  TimeRangeInputDemos,
  RichTextEditorDemos,
  React
};