import { NotificationsDemos, StackDemos, SliderDemos, TooltipDemos } from '@mantine/demos';
import { Anchor } from '@mantine/core';
import * as React from 'react';
export default {
  NotificationsDemos,
  StackDemos,
  SliderDemos,
  TooltipDemos,
  Anchor,
  React
};